import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Link, useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { Theme } from "../Socials/Socials";
import { LogoQuery } from "../../../graphql-types";

interface Props {
  homeLink?: Boolean;
  theme?: Theme;
}

export const Logo: React.FC<Props> = React.memo(
  ({ homeLink = false, theme = Theme.white }) => {
    const { settings } = useStaticQuery<LogoQuery>(QUERY);
    return (
      <>
        {(!homeLink && (
          <GatsbyImage
            image={
              (theme === Theme.white &&
                settings?.main?.white?.asset?.gatsbyImageData) ||
              settings?.main?.black?.asset?.gatsbyImageData
            }
            alt="concave"
            imgStyle={{ objectFit: "contain" }}
          />
        )) || (
          <Link to="/">
            <GatsbyImage
              image={
                (theme === Theme.white &&
                  settings?.main?.white?.asset?.gatsbyImageData) ||
                settings?.main?.black?.asset?.gatsbyImageData
              }
              alt="concave"
            />
          </Link>
        )}
      </>
    );
  }
);

const QUERY = graphql`
  query logo {
    settings: sanitySettings {
      main {
        white {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
        black {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`;
