import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import * as styles from "./socials.module.scss";
import { SocialsQuery } from "../../../graphql-types";

export enum Theme {
  white = "white",
  black = "black",
}

interface Props {
  theme: Theme;
  row?: Boolean;
}

export const Socials: FunctionComponent<Props> = ({ theme, row }) => {
  const { settings } = useStaticQuery<SocialsQuery>(query);

  return (
    <div className={`${styles.wrapper} ${(row && styles.row) || ""}`}>
      {settings?.socials?.map((social: any, index: number) => (
        <div className={styles.container} key={`social-${index}`}>
          <a href={social.url} target="_blank" rel="noopener">
            <img
              src={social.logos[theme].asset.url}
              alt={social.title}
              className={styles.img}
            />
          </a>
        </div>
      ))}
    </div>
  );
};

const query = graphql`
  query Socials {
    settings: sanitySettings {
      socials {
        logos {
          black {
            asset {
              url
            }
          }
          white {
            asset {
              url
            }
          }
        }
        title
        url
      }
    }
  }
`;
